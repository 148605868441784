import { styled, Tooltip } from '@mui/material';

import Link from 'src/components/Link';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const NavigationLogo = styled('img')(`
  @media only screen and (max-width: 600px) {
      height: 80px;
      width: 180px;
  }`);

function Logo() {
  return (
    <Tooltip arrow placement="right" title="Supplify">
      <LogoWrapper href="/">
        <NavigationLogo
          src={'/logo_supplify_white.png'}
          height={90}
          width={210}
          className="navigation-logo"
        />
      </LogoWrapper>
    </Tooltip>
  );
}

export default Logo;
