import { alpha, Box, Card, Container, Typography, styled } from '@mui/material';

import HeaderMenu from '@/layouts/AccentHeaderLayout/Header/Menu';
import HeaderUserbox from './Userbox';
import Logo from './Logo';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import AccentSidebar from '../AccentSidebar';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: 60px;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

function Header() {
  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <Logo />

        <Typography sx={{ color: 'cyan' }} variant="h5">
          Beta
        </Typography>
      </Box>

      <Box display="flex" alignItems="left">
        <Container maxWidth="xl">
          <Box
            component="span"
            sx={{ display: { xs: 'none', md: 'inline-block' } }}
          >
            <HeaderMenu />
          </Box>
        </Container>
      </Box>

      <Box display="flex" alignItems="center">
        <Box
          sx={{
            mr: { md: 0, xs: 6 },
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <HeaderUserbox />
        </Box>
        <Box
          component="span"
          sx={{ display: { md: 'none', xs: 'inline-block' } }}
        >
          <AccentSidebar />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
