import React, { FC } from 'react';

import { slide as Menu } from 'react-burger-menu';
import { Box } from '@mui/material';

import { PortalWrapper } from '@/components/PortalWrapper';
import style from './side-bar-mobile.module.css';

interface SideBarMobileProps {
  isMenuOpen: boolean;
  handleStateChange: (state) => void;
  isRight?: boolean;
  bgColor?: string;
  sx?: object;
}

export const SideBarMobile: FC<SideBarMobileProps> = ({
  children,
  isMenuOpen,
  handleStateChange,
  isRight = true,
  bgColor = 'blue',
  sx = { display: { md: 'none', xs: 'inline-block' } }
}) => {
  return (
    <PortalWrapper>
      <Box className={style.container} sx={sx}>
        <Menu
          isOpen={isMenuOpen}
          onStateChange={(state) => handleStateChange(state)}
          customCrossIcon={
            <div
              className={
                bgColor === 'blue'
                  ? `${style.cross} ${style.blue}`
                  : `${style.cross} ${style.white}`
              }
            />
          }
          className={style.wrapper}
          right={isRight}
          outerContainerId="body"
          menuClassName={
            bgColor === 'blue'
              ? `${style.menu} ${style.blue}`
              : `${style.menu} ${style.white}`
          }
          bodyClassName={style.body}
          burgerButtonClassName={style.burger}
          burgerBarClassName={style.burger_bg}
          overlayClassName={style.overlay}
          crossButtonClassName={style.close}
          crossClassName={style.close_bg}
        >
          {children}
        </Menu>
      </Box>
    </PortalWrapper>
  );
};
