import React, { FC, useContext } from 'react';

import { useRouter } from 'next/router';
import NextLink from 'next/link';
import {
  Avatar,
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography
} from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useAuth0 } from '@auth0/auth0-react';

import {
  menuItems,
  adminMenuItems,
  guestMenuItems
} from '@/layouts/AccentHeaderLayout/constants';
import { UserProfileContext } from '@/contexts/UserProfileContext';

const MenuListWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      width: 100%;
      height: 100%;
      
      .MuiListItem-root {
        color: ${theme.sidebar.menuItemColor};
        width: 100%;
        margin: 10px 0;
        cursor: pointer;

        &.Mui-selected,
        &:hover:not(:first-of-type) {
          background-color: ${theme.sidebar.menuItemBgActive};
          color: ${theme.sidebar.menuItemColorActive};
        }
      }
    }
`
);

interface SidebarMenuProps {
  closeSidebar: () => void;
}

const SidebarMenu: FC<SidebarMenuProps> = ({ closeSidebar }) => {
  const router = useRouter();

  const { user } = useContext(UserProfileContext);
  const { user: userAuth0, loginWithRedirect, logout } = useAuth0();

  const userIsAdmin = user?.roles?.includes('admin');

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithRedirect();
      closeSidebar();
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      closeSidebar();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const navBar = userIsAdmin
    ? adminMenuItems
    : user
    ? menuItems
    : guestMenuItems;

  const name = `${user?.user_profile?.first_name} ${user?.user_profile?.last_name}`;

  return (
    <MenuListWrapper>
      <List disablePadding sx={{ pt: 5 }}>
        {user && (
          <ListItem
            onClick={() => {
              router.push({
                pathname: '/profile'
              });
              closeSidebar();
            }}
          >
            <Avatar variant="rounded" alt={user.name} src={userAuth0.picture} />

            <Box component="span">
              <Typography variant="body1" sx={{ ml: 1.2 }}>
                {name}
              </Typography>
            </Box>
          </ListItem>
        )}

        {navBar.map((MenuItem) => (
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            key={MenuItem.label}
            onClick={closeSidebar}
            selected={MenuItem.link === router.pathname}
            sx={{ p: 0 }}
          >
            <NextLink href={MenuItem.link} passHref>
              <Link
                sx={{
                  color: 'inherit',
                  width: '100%'
                }}
                underline="none"
              >
                <Box sx={{ display: 'flex', width: '100%', p: 2 }}>
                  <MenuItem.Icon sx={{ mr: 2 }} />
                  <ListItemText
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: 'h4'
                    }}
                    primary={MenuItem.label}
                  />
                </Box>
              </Link>
            </NextLink>
          </ListItem>
        ))}
      </List>

      {user && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="primary" onClick={handleLogout} sx={{ width: '100%' }}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      )}

      {!user && (
        <Button onClick={handleLogin} color="primary" sx={{ width: '100%' }}>
          Login
        </Button>
      )}
    </MenuListWrapper>
  );
};

export default SidebarMenu;
