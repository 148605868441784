import React, { useContext, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  styled,
  Typography
} from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useAuth0 } from '@auth0/auth0-react';

import { UserProfileContext } from '@/contexts/UserProfileContext';
import { Can } from '@/contexts/AbilityContext';

const LoaderWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-itmes: center;
  justify-content: center;
  width: 180px;
  background: ${theme.colors.primary.lighter};
  `
);

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabelWhite = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.alpha.trueWhite[70]};
        display: block;
`
);

const UserBoxLabelBlack = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

function HeaderUserbox() {
  const { user, userIsLoading } = useContext(UserProfileContext);
  const { user: userAuth0, logout, isLoading, loginWithRedirect } = useAuth0();
  const router = useRouter();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const name = user?.user_profile?.first_name
    ? `${user?.user_profile?.first_name} ${user?.user_profile?.last_name}`
    : user?.user_profile?.email;

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithRedirect();
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = (): void => setOpen(true);

  const handleClose = (): void => setOpen(false);

  if (!user) {
    return (
      <Button onClick={handleLogin} variant="contained">
        Login
      </Button>
    );
  }

  if (userIsLoading || isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress
          size={32}
          disableShrink
          thickness={3}
          sx={{ color: 'white' }}
        />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={name} src={userAuth0.picture} />

        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'inline-block' } }}
        >
          <UserBoxText>
            <UserBoxLabelWhite variant="body1">{name}</UserBoxLabelWhite>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{ display: { xs: 'none', sm: 'inline-block' } }}
        >
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Box>
      </UserBoxButton>

      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{ minWidth: 210, display: 'flex', alignItems: 'center' }}
        >
          <Avatar variant="rounded" alt={name} src={userAuth0.picture} />

          <UserBoxText>
            <UserBoxLabelBlack variant="body1">{name}</UserBoxLabelBlack>
          </UserBoxText>
        </MenuUserBox>

        <Divider sx={{ mb: 0 }} />
        <Box sx={{ padding: '10px 0' }}>
          <MenuListWrapperPrimary
            disablePadding
            sx={{ paddingBottom: '0', paddingTop: '0' }}
          >
            <MenuItem>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5' }}
                primary={'Profile'}
                onClick={() => {
                  router.push({
                    pathname: '/profile'
                  });
                  handleClose();
                }}
              />
            </MenuItem>
          </MenuListWrapperPrimary>

          <Can I="index" this="Version">
            <MenuListWrapperPrimary
              disablePadding
              sx={{ paddingBottom: '0', paddingTop: '0' }}
            >
              <MenuItem>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5' }}
                  primary={'Company updates'}
                  onClick={() => {
                    router.push({
                      pathname: '/companies/updates'
                    });
                    handleClose();
                  }}
                />
              </MenuItem>
            </MenuListWrapperPrimary>
          </Can>
        </Box>
        <Divider sx={{ mb: 0 }} />

        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {'Sign out'}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
