import { useContext } from 'react';

import NextLink from 'next/link';
import { Box, Link, List, ListItem, ListItemText, styled } from '@mui/material';

import {
  menuItems,
  guestMenuItems,
  adminMenuItems
} from '@/layouts/AccentHeaderLayout/constants';
import { UserProfileContext } from '@/contexts/UserProfileContext';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                color: white;
                padding: 0;
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }
                .MuiLink-root {
                  &.active,
                  &:active,
                  &:hover {
                      color: #43dea3;
                      background: transparent;
                      cursor: pointer;
                  }
                }
                
            }
        }
`
);

function HeaderMenu() {
  const { user } = useContext(UserProfileContext);

  const userIsAdmin = user?.roles?.includes('admin');
  const navBar = userIsAdmin
    ? adminMenuItems
    : user
    ? menuItems
    : guestMenuItems;

  return (
    <ListWrapper>
      <List disablePadding component={Box} display="flex">
        {navBar.map((list) => (
          <ListItem
            key={list.link}
            classes={{ root: 'MuiListItem-indicators' }}
            sx={{ width: 'max-content', mr: 3 }}
          >
            <NextLink href={list.link} passHref>
              <Link sx={{ color: 'inherit' }} underline="none">
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: 'h4'
                    }}
                    primary={list.label}
                  />
                </Box>
              </Link>
            </NextLink>
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  );
}

export default HeaderMenu;
