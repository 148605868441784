import TableChartIcon from '@mui/icons-material/TableChart';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DiamondIcon from '@mui/icons-material/Diamond';
import ChatIcon from '@mui/icons-material/Chat';

export const menuItems = [
  { label: 'Companies', link: '/companies', Icon: TableChartIcon },
  { label: 'SPC Tech Map', link: '/supply-chain-tech-map', Icon: DiamondIcon },
  {
    label: 'Open Leads',
    link: '/open-projects',
    Icon: RoomPreferencesIcon
  },
  { label: 'FAQ', link: '/faq', Icon: QuestionAnswerIcon },
  { label: 'Feedback', link: '/feedback', Icon: ChatIcon }
];

export const guestMenuItems = [
  { label: 'Companies', link: '/companies', Icon: TableChartIcon },
  { label: 'SPC Tech Map', link: '/supply-chain-tech-map', Icon: DiamondIcon }
];

export const adminMenuItems = [
  { label: 'Companies', link: '/companies', Icon: TableChartIcon },
  { label: 'Chat', link: '/gpt', Icon: TableChartIcon },
  { label: 'Leaders Network', link: '/leaders', Icon: TableChartIcon },
  { label: 'SPC Tech Map', link: '/supply-chain-tech-map', Icon: DiamondIcon },
  {
    label: 'Open Leads',
    link: '/open-projects',
    Icon: RoomPreferencesIcon
  },
  { label: 'FAQ', link: '/faq', Icon: QuestionAnswerIcon },
  { label: 'Feedback', link: '/feedback', Icon: ChatIcon }
];
