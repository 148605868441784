import { useState } from 'react';

import { Box, styled } from '@mui/material';

import { SideBarMobile } from '@/components/SideBarMobile';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.sidebar.menuItemIconColor};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

function AccentSidebar() {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleStateChange = (state) => {
    setIsMenuActive(state.isOpen);
  };

  const closeSidebar = () => {
    setIsMenuActive(false);
  };

  return (
    <SideBarMobile
      isMenuOpen={isMenuActive}
      handleStateChange={handleStateChange}
      bgColor="white"
    >
      <SidebarWrapper>
        <SidebarMenu closeSidebar={closeSidebar} />
      </SidebarWrapper>
    </SideBarMobile>
  );
}

export default AccentSidebar;
